<template>
  <v-sheet class="treemap">
    <div>
      <div class="text-subtitle-2 ma-2 text-center font-weight-light">
          {{ $vuetify.lang.t("$vuetify.dashboard.packageTitle") }}
          <v-btn class="ml-2" icon @click="toggleLinear = !toggleLinear">
            <v-icon v-if="toggleLinear"> mdi-chart-line </v-icon>
            <v-icon v-else> mdi-chart-bell-curve-cumulative </v-icon>
          </v-btn>
        </div>
        <v-sheet :elevation="2" :height="400" class="pa-3 d-flex justify-center overflow-y-auto">
          <bar-chart
            class="mb-6"
            :show-legend="false"
            v-if="
              packagingData && packagingData.labels && packagingData.labels.length > 0
            "
            :showTitle="true"
            :title="`Total Packaged ${totalPackaged}`"
            :data="packagingData"
            horizontal
            :logarithmic="toggleLinear"
            @click="showDetails"
            :responsive="true"
            :maintainAspectRatio="false"
            :height="calculateChartHeight(packagingData.labels.length)"
          />
          <div v-else class="text-center text-caption font-light-weight">
            {{ $vuetify.lang.t("$vuetify.noDataText") }}
          </div>
        </v-sheet>
    </div>
    <v-dialog
      :fullscreen="$vuetify.breakpoint.xsOnly"
      v-model="showDetailsDialog"
      style="z-index: 1000"
    >
      <v-card>
        <v-card-title class="justify-end">
          <div>
            <v-btn @click="showDetailsDialog = null" icon
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </div>
        </v-card-title>
        <v-card-text>
          <daily-distribution-chart type="packaging" :product="product" :startDate="startDate" :endDate="endDate"/>

          <infinite-scroll
            v-if="showDetailsDialog"
            :key="infiniteScrollKey"
            @fetchData="fetchData"
            :totalPages.sync="totalPages"
          >
            <div
              class="d-flex justify-center text-subtitle-1 mb-2"
              v-if="worksheets && worksheets.length > 0"
            >
              <div class="ml-2 text-caption">
                {{ $vuetify.lang.t("$vuetify.label.foundX", totalSizes ? totalSizes : 0 ) }}
              </div>
            </div>
            <v-divider />
            <worksheet-card
              v-for="(worksheet, index) in worksheets"
              :worksheet="worksheet"
              :index="index + 1"
              :key="worksheet ? worksheet.id : 'null'"
            />
          </infinite-scroll>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-sheet>
</template>

<script>
import { mapActions } from "vuex";
const WorksheetCard = () =>
  import("@/components/processWorksheet/WorksheetCard.vue");
const BarChart = () => import("@/components/charts/BarChart.vue");
import {calculateChartHeight} from "@/components/charts/utils.js"
const DailyDistributionChart = () => import('@/components/dashboard/DailyDistributionChart.vue');

export default {
  props: {
    startDate: {
      type: String,
      required: true
    },
    endDate: {
      type: String,
      required: true
    }
  },
  data: () => {
    return {
      showDetailsDialog: false,
      totalPages: null,
      worksheets: [],
      packagingData: null,
      ts: Date.now(),
      infiniteScrollKey: Date.now(),
      productId: null,
      productName: null,
      toggleLinear: false
    };
  },
  computed: {
    product(){
      return {
        id: this.productId,
        name: this.productName
      }
    }
  },
  components: {
    WorksheetCard,
    BarChart,
    DailyDistributionChart
  },
  mounted() {
    this.initData();
  },
  watch: {
    startDate: function(newVal, oldVal) {
      this.initData();
    },
    endDate: function(newVal, oldVal) {
      this.initData();
    },
    showDetailsDialog(val) {
      if (!val) {
        console.log("dialog closed... reset values");
        this.worksheets = [];
        this.totalPages = null;
      } else {
        this.infiniteScrollKey = Date.now();
      }
    },
  },
  methods: {
    ...mapActions("processWorksheet", ["fetchProcessWorksheets"]),
    ...mapActions("messages", [
      "addErrorMessage",
      "addMessage",
      "addSuccessMessage",
    ]),
    calculateChartHeight(count){
      return calculateChartHeight(count);
    },
    initData() {
      this.loadPackagingSummary().then((summary) => {
        this.packagingData = summary;
        const total = summary.datasets[0].data.reduce( (sum, i) => sum += i, 0);
        this.totalPackaged = this.$options.filters.formatNumber(total);
      });
    },
    async loadPackagingSummary() {
      const params = {
        start: this.startDate,
        end: this.endDate,
      };
      return await this.$axios
        .get("/reports/packaging/packaging-summary", {
          params,
        })
        .then((response) => {
          const dataset = response.data;
          dataset.sort((a, b) => b.netPackagedWeight - a.netPackagedWeight);
          const chartData = {
            labels: [],
            datasets: [
              {
                label: 'Receiving',
                backgroundColor: "#f87979",
                data: [],
                dataObject: [],
              },
            ],
          };

          dataset.forEach((d) => {
            const weight = this.$options.filters.formatInteger(d.netPackagedWeight ? d.netPackagedWeight:0);
            chartData.labels.push(`${d.productName} (${weight})`);
            chartData.datasets[0].data.push(d.netPackagedWeight);
            chartData.datasets[0].dataObject.push(d);
          });

          return chartData;
        });
    },
    showDetails(item) {
      if (item && item.data) {
        const productData = item.data;
        this.showDetailsDialog = true;
        this.productId = productData.productId;
        this.productName = productData.productName;
      }
    },
    fetchData({ page, done, empty }) {
      const params = {
        page,
        size: 10,
        sort: "id,desc",
        productId: this.productId,
        start: this.startDate,
        end: this.endDate,
        statuses: "submitted,used",
      };

      this.$axios
        .get("/processWorksheet/by-process-time", { params })
        .then((resp) => {
          const newPage = resp.data.content;
          this.worksheets = this.worksheets.concat(newPage);
          this.totalPages = resp.data.totalPages;
          this.totalSizes = resp.data.totalElements;
          if (this.totalSizes > 0) {
            done();
          } else {
            empty();
          }
        })
        .catch((err) => {
          this.addErrorMessage(err);
        });
    },
  },
};
</script>